import React from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import styled from "styled-components";

const TableWrapper = styled.div`
  .rdt_TableHead .rdt_TableCol {
    font-weight: bold;
  }
`;

const DataTableAdmin = ({
  columns,
  data,
  pagination,
  props
}) => {
  return (
    <TableWrapper {...props}>
      <DataTable
        columns={columns}
        data={data}
        pagination={pagination}
      />
    </TableWrapper>
  );
}

DataTableAdmin.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  pagination: PropTypes.bool,
};

DataTableAdmin.defaultProps = {
  columns: [],
  data: [],
  pagination: true,
};

export default DataTableAdmin;
